<template>
  <div class="competitor-component">
    <div class="competitor-component__avatar">
      <Icon :size="24" :type="'account'" />
    </div>

    <div class="competitor-component__name">
      <span class='competitor-component__name-text'>{{ name }}</span>
    </div>

    <div v-show="isUrlCopied" class="tooltip">
      <Icon :size="16" :type="'copy'"/>
      Link copied to clipboard
    </div>

    <a-button @click="copyLink" class="competitor-component__button competitor-component__button_link ant-btn">
      <Icon :size="24" :type="'chain'"/>
    </a-button>

    <a-button @click="removeInvite(competitor.hash)" class="competitor-component__button ant-btn">
      <Icon :size="24" :type="'check'"/>
    </a-button>
  </div>
</template>

<script>
import {copyTextToClipboard} from "@/helpers";
import Icon from '@/components/Icon'
import { mapMutations } from 'vuex'

export default {
  name: 'CompetitorByLink',
  components: { Icon },
  props: {
    competitor: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isUrlCopied: false
    }
  },
  computed: {
    name () {
      return this.competitor.username
    },
    avatar () {
      return this.competitor?.photo
    },
    hash() {
      return this.competitor?.hash
    }
  },
  methods: {
    // REMOVE_INVITE_BY_LINK
    ...mapMutations('newcompetition', [ 'REMOVE_INVITE_BY_LINK' ]),

    removeInvite(hash) {
      this.REMOVE_INVITE_BY_LINK(hash);
    },

    copyLink() {
      copyTextToClipboard(`${window.location.origin}/details/${this.hash}`)
      this.isUrlCopied = true
      setTimeout(() => {
        this.isUrlCopied = false
      }, 2500)
    }
  }
}
</script>

<style lang="stylus" scoped>
.newcompetition-view .newcompetition-competitors .competitor-component
  position: relative
  padding-left: 38px;
  margin-bottom: 20px

  &__avatar {
    width 40px
    height 40px
    border-radius: 50%;
    background: #D8D8D8;
    display flex
    justify-content center
    align-items center
    margin-right 10px
    color: #383D54;
  }

  &__name {
    flex-grow 1
  }

  &__name-text {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 135px;
    white-space: pre;
  }

  &__button {
    height: 40px
    width: 40px
    padding 8px
    border: 0
    background: #262b3f;
    margin: 0 0 0 12px

    &_link {
      border 1.5px solid #6293FF
      color #6293FF
      background-color: transparent
    }
  }

  .tooltip {
    display: flex;
    flex-direction row
    align-items: center;
    gap: 8px;
    background-color: #6293ff;
    padding: 14px 16px;
    border-radius: 8px;
    color: #fff;
    position: absolute;
    right: 52px;
    top: -54px;
    line-height: 1;

    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      right: 15px;
      top: auto
      bottom: -2px;
      border-radius: 2px;
      background-color: #6293ff;
      transform: rotate(50deg) skew(10deg);
    }
  }

.link-element
  display: flex
  align-items center
  color #0CEB15
  gap 0.5rem
  & svg
    width: 16px
    height: 16px
</style>
