<template>
  <subview class="newcompetition-competitors">
    <view-header>
      <custom-header :router-back="goBackToCompetitionDetailsPage">
        <template v-slot:default>
          <div>
            <Icon :size="24" type="arrow-left"/>

            {{ $t('pages.newcompetition.competitors.title') }}
          </div>
        </template>
        <template #last>
          <div class="current-step"> {{ $t('pages.newcompetition.step') }} 3/4 </div>
        </template>
      </custom-header>
    </view-header>

    <view-content>
      <div v-if="showTooltip" @click="closeTooltip" class='tooltip__wrapper'>
        <div class="tooltip">
          <div class="tooltip__text">Use “Generate External Link” to invite people outside of Competa.</div>
          <a-button class="tooltip__button" @click="closeTooltip">Got it</a-button>
        </div>
      </div>

      <div class='search-block'>
        <a-input-search v-model="search" :placeholder="$t('search.userby')" size="large">
          <a-button slot="enterButton">
            <Icon :size="24" type="search"/>
          </a-button>
        </a-input-search>

        <a-button v-if="this.selectedSport.name === 'Swimming'" class="search-block__link" @click="openExternalLinkGenerator" :disabled="!isWeCanAddMoreUsers">
          <Icon :size="24" :type="'chain'"/>
        </a-button>
      </div>

      <CompetitorByLink v-for="competitor in invitesByLink" :key="competitor.hash" :competitor="competitor"/>
      <Competitor v-for="competitor in invitedCompetitors" :key="competitor.id" :competitor="competitor"/>

      <div v-if="invitesByLink.length > 0 || invitedCompetitors.length > 0" class='custom-divider'><span>Invite more</span></div>

      <Competitor v-for="competitor in competitors" :key="competitor.id" :competitor="competitor"/>
    </view-content>

    <view-footer>
      <a-button @click="next" :class="buttonClasses">
        {{ $t('pages.newcompetition.next') }}

        <Icon :size="20" type="arrow-right" />
      </a-button>
    </view-footer>
  </subview>
</template>

<script>
import Icon from '@/components/Icon'
import Competitor from '@/components/NewCompetition/Competitor'
import CompetitorByLink from '@/components/NewCompetition/CompetitorByLink'
import { mapState } from 'vuex'
import newcompetition from '@/mixins/newcompetition'

export default {
  name: 'Competitiors',
  mixins: [
    newcompetition
  ],
  components: {
    CompetitorByLink,
    Icon,
    Competitor
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === 'newcompetition.details') {
      next(vm => vm.$store.dispatch('newcompetition/resetCompetitorsList'));
    }
    next();
  },
  computed: {
    ...mapState({
      opponents: ({ competition }) => competition.opponents
    }),
    ...mapState('newcompetition', {
      invitedCompetitors: ({ competitors }) => competitors,
    }),
    competitors () {
      const { search } = this

      const list = this.opponents
        // hide selected competitors
        ?.filter(c => !this.invitedCompetitors.includes(c))
        .filter(({ name, nickname }) => {
        return !search
          || name.toLowerCase().includes(search.toLowerCase())
          || nickname?.toLowerCase().includes(search.toLowerCase())

      }) || []
      return list.sort((a, b) => (a.position === 0 ? Infinity : a.position) - (b.position === 0 ? Infinity : b.position));
    },

    disabled () {
      // return !this.selectedCompetitors.length && !this.invitesByLink.length
      return !this.selectedCompetitors.length
    },
    buttonClasses () {
      const { disabled } = this

      return { disabled }
    }
  },
  data () {
    return {
      search: null,
      showTooltip: true
    }
  },
  methods: {
    next () {
      // invites removed
      // this.$router.push({ name: 'newcompetition.invites' })
      this.$router.push({ name: 'newcompetition.preview' })
    },
    openExternalLinkGenerator() {
      this.$router.push({ name: 'newcompetition.competitors-external-link' })
    },
    closeTooltip() {
      this.showTooltip = false;
    },
    goBackToCompetitionDetailsPage() {
      this.$router.push({name: 'newcompetition.details'})
    }
  }
}
</script>

<style lang="stylus">
  .search-block {
    display flex

    input,
    .ant-input-search .ant-input-group-addon {
      background-color: #2D303A
    }

    .ant-input-search input {
      height 48px;
    }

    &__link {
      width 40px
      padding 12px 8px
      border: 0
      margin-bottom: 28px
      margin-left: 12px

      &.ant-btn .icon:last-child {
        margin 0
      }

      &:disabled {
        color: #7b8b97;
        background: #1d2130;
        pointer-events: none;
        border: none;
      }
    }
  }

  .tooltip {
    display: flex;
    align-items: center;
    background-color: #6293ff;
    padding: 14px 16px;
    border-radius: 8px;
    color: #fff;
    position: absolute;
    right: 16px;
    top: 138px;
    line-height: 1;
    width: 281px;
    flex-direction: column;
    gap: 16px;

    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      right: 15px;
      top: -2px;
      border-radius: 2px;
      background-color: #6293ff;
      transform: rotate(50deg) skew(10deg);
    }

    &__wrapper {
      position: fixed
      background-color: transparent
      inset: 0
      z-index 2
    }

    &__text {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
    }

    &__button {
      width: 69px;
      height: 36px;
      background: #4D7DE4;
      border-radius: 8px;
      border: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
    }
  }

.external-link
  margin-bottom 40px
  & svg
    width 16px
    height 16px
    margin-right 6px
.external-link[disabled]
  color white
</style>
