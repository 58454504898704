<template>
  <div class="competitor-component">
    <div class="position"> {{ currentPosition }} </div>

    <a-avatar v-show="competitor.photo" :size="24" :src="avatar" />
    <div v-show="!competitor.photo" class="competitor-component__initials">{{ initials }}</div>

    <div class='competitor-component__info'>
      <div class='competitor-component__name'>{{ name }}</div>
      <div class="competitor-component__country">
        <img :src="country" alt=""/>
      </div>

      <div class="competitor-component__rating">
        <Icon :size="12" type="star"/>
        <span> {{ rating }} </span>
      </div>
    </div>

    <a-button :class="buttonClasses" @click="select" class="competitor-component__button">
      <Icon :size="24" :type="selected ? 'check' : 'plus'"/>

      <!-- {{ $t(selected ? 'pages.newcompetition.competitors.competitor.added' : 'pages.newcompetition.competitors.competitor.add') }} -->
    </a-button>
  </div>
</template>

<script>
import Icon from '@/components/Icon'
import { mapState } from 'vuex'
import newcompetition from '@/mixins/newcompetition'

export default {
  name: 'Competitor',
  components: {
    Icon
  },
  props: {
    competitor: {
      type: Object,
      default: null
    }
  },
  mixins: [
    newcompetition
  ],
  computed: {
    ...mapState('newcompetition', ['competitors']),
    id () {
      return this.competitor.id
    },
    name () {
      return this.competitor.name
    },
    avatar () {
      return this.competitor.photo
    },
    rating () {
      return this.competitor.rating
    },
    country () {
      return this.competitor?.country?.icon
    },
    position () {
      return this.competitor.position
    },
    selected () {
      return !!this.competitors.find(({ id }) => id === this.id)
    },
    disabled () {
      return !this.selected && !this.isWeCanAddMoreUsers
    },
    buttonClasses () {
      return {
        disabled: this.disabled,
        secondary: this.selected
      }
    },
    currentPosition () {
      return this.position === 0 ? '- -' : this.position > 100 ? '—' :  this.position;
    },
    initials() {
      return this.name.split(' ').slice(0, 2).map(e => e[0].toUpperCase()).join('');
    },
  },
  methods: {
    select () {
      this.selected ? this.removeCompetitor(this.competitor) : this.addCompetitor(this.competitor)
    },
  }
}
</script>

<style lang="stylus" scoped>
  .newcompetition-view .newcompetition-competitors .competitor-component {
    margin-bottom 20px

    .ant-avatar {
      min-width 40px
      min-height 40px
      border-radius: 50%;
      margin: 0 10px 0 0;
    }

    &__initials {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      margin-right 10px

      background: #D8D8D8;
      border-radius 50%
      color: #2F354D;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
    }

    &__info {
      display flex
      flex-wrap wrap;
      flex: 1;
    }

    &__name {
      margin-right 8px
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #EEEEEE;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
      white-space: pre;
    }

    &__country {
      img {
        width: 16px;
      }
    }

    &__rating {
      width: 100%;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: rgba(255, 255, 255, 0.5);
      margin-top: 4px;
      display: flex;
      align-items: center;
      gap: 4px;
    }

    &__button {
      height: 40px
      width: 40px
      padding 8px

      &.secondary {
        background: #262B3F;
        border: 0
      }
    }

    .name {

    }
  }
</style>
